.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.venue-type-SCG {
  background-color: #215a41; /* Replace with the color for venue type 1 */
  color: white;
}

.venue-type-SFS {
  background-color: #003781; /* Replace with the color for venue type 1 */
  color: white;
}

.venue-type-Accor {
  background-color: #b88d5b; /* Replace with the color for venue type 1 */
}

.venue-type-WSEC {
  background-color: #f5c438; /* Replace with the color for venue type 1 */
}

.venue-type-Commbank {
  background-color: #ffcc00; /* Replace with the color for venue type 1 */
}

.venue-type-NEC {
  background-color: #30723c66; /* Replace with the color for venue type 1 */
}

.venue-type-MJS {
  background-color: #080f25; /* Replace with the color for venue type 1 */
  color: white;
}

.print-friendly-datagrid {
  display: none;
}

@media print {
  .print-friendly-datagrid {
    display: block;
  }

  .MuiDataGrid-root {
    display: none;
  }
}
